import * as OfflinePluginRuntime from 'offline-plugin/runtime';



import './panel.html';
import './panel.scss';
import './scripts/script';



OfflinePluginRuntime.install();


